<template>
  <div>
    <MainLayout>
      <template v-if="sourceObject" v-slot:navbar-title>{{
        `${sourceObject ? sourceObject.name : ""}`
      }}</template>
      <template v-slot:navbar-btn-wrap>
        <ParentPopup
          name="Воспитанника"
          description="Воспитанник будет перенесен. Вы действительно хотите продолжить?"
          :title="sourceObject ? sourceObject.name : ''"
          :save-function="transferChildrenGroup"
        />
        <DeleteAgreementPopup
          name="Воспитанника"
          description="Опекуны воспитанника будут удалены и потеряют доступ к объекту. Оплаченные доступы будут аннулированы."
          :title="sourceObject ? sourceObject.name : ''"
          :delete-function="deleteChildren"
          agreeDescription="Аннулировать платные доступы"
          agreeSubDescription="Доступы для опекунов, которые связаны с воспитанниками из других детских групп, будут перенесены без удаления."
        />
        <router-link
          v-if="urlQuery && urlQuery.id"
          :to="`/childrenEdit/` + urlQuery.id"
          class="btn btn-edit btn_color_grey"
          >редактировать</router-link
        >
      </template>
      <SlideLayout :tabs="slideTabs">
        <div class="keep__title">
          <p class="keep__text">Общая информация</p>
        </div>
      </SlideLayout>
      <template>
        <div class="  mt-6">
          <p class="mb-3 d-flex" v-if="sourceObject">
            <span class="childrenInfo ">Табельный номер:</span>
            <span class="childrenInfoText">{{ tableData[0].value }}</span>
          </p>
          <!--          <p class="mb-3" v-if="sourceObject">-->
          <!--            <span class="childrenInfo mb-2">Воспитанник:</span>-->
          <!--            <span class="childrenInfoText">{{ tableData[1].value }}</span>-->
          <!--          </p>-->
          <p class="mb-3 d-flex" v-if="sourceObject">
            <span class="childrenInfo mb-2">Детская группа:</span>
            <span class="childrenInfoText"
              >{{ tableData[2].value }}, {{ tableData[3].value }}</span
            >
          </p>
        </div>
      </template>

      <template> <Parents /></template>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
// import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import Parents from "@/components/info/children/Parents";
import DeleteAgreementPopup from "@/components/popup/DeleteAgreementPopup.vue";
import ParentPopup from "@/components/popup/ParentPopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ChildrenInfosPage",
  components: {
    ParentPopup,
    DeleteAgreementPopup,
    Parents,
    // BasicTableWithoutHeader,
    SlideLayout,
    MainLayout
  },
  data() {
    return {};
  },
  computed: {
    slideTabs() {
      return [
        {
          text: "Общая информация",
          icon: "i"
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getChildrenInfos;
    },
    tableData() {
      return [
        {
          text: "Табельный номер",
          value: this.sourceObject.number
        },
        {
          text: "Воспитанник",
          value: `${this.sourceObject.name} `
        },

        {
          text: "Детская группа",
          value: this.sourceObject.children_group
            ? this.sourceObject.children_group.number
            : ""
        },
        {
          text: "Название",
          value: this.sourceObject.children_group
            ? this.sourceObject.children_group.name
            : ""
        }
      ];
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query,
      id: this.urlQuery.id,
      kindergarten_org: this.$store.getters.getCurrentOrganization
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchChildrenById")
      .then(() => {
        this.$store.dispatch("fetchChildrenGroupByIdListForChildrenGroup");
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
    this.$store.dispatch("fetchParentsList", this.$route.params.kindergartenId);
  },
  methods: {
    deleteChildren() {
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });

      this.$store
        .dispatch("deleteChildren", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.children)
          );
        });
    },
    transferChildrenGroup() {
      this.$store.commit("setRouterQuery", {
        routes: this.$route.params
      });

      this.$store
        .dispatch("transferChildren", {
          ids: this.urlQuery.id
        })
        .then(() => {
          this.$store.commit("changeEditChildren", {
            fieldName: "children_group",
            value: this.$store.getters.getCreateChildren.children_group
          });
          this.$store.commit("setChildrenCreateForm", {
            listName: "children_group",
            value: null
          });
          this.$store.dispatch(
            "successNotification",
            actionMessage.transfer(successMessageDictionary.children)
          );
          this.$store.dispatch(
            "fetchParentsList",
            this.$route.params.kindergartenId
          );

          this.$store
            .dispatch("fetchChildrenById")
            .then(() => {
              this.$store.dispatch(
                "fetchChildrenGroupByIdListForChildrenGroup"
              );
            })
            .finally(() => {
              this.isDateLoaded = false;
            });
        });
    }
  }
};
</script>

<style scoped>
.childrenInfo {
  line-height: 2rem;
  font-family: Inter, sans-serif !important;
  text-align: initial;
  padding: 0 20px;
  color: rgb(80, 90, 109);
  font-weight: 500;
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
  width: 230px;
}
.childrenInfoText {
  line-height: 2rem;
  font-family: Inter, sans-serif !important;
  padding: 0 15px 0 0;
  text-align: initial;
  color: rgb(80, 90, 109);
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
}
</style>
