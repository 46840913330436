<template>
  <v-dialog v-model="dialog" persistent max-width="420">
    <template v-slot:activator="{}">
      <button @click="showDialog()" class="btn btn-delete btn_color_red">
        удалить {{ name }}
      </button>
    </template>
    <v-card>
      <v-card-title v-if="title" class="delete-title delete-center">
        <div>Удалить {{ name }}</div>
        <span class="delete-bold"> "{{ title }}" ?</span>
      </v-card-title>
      <v-card-text
        v-if="isDescription"
        class="delete-center d-flex flex-column"
        :class="{ 'pt-8': !title }"
        style="white-space: pre-wrap;"
      >
        <span class="text-align_left mt-2">{{ description }}</span>
        <div class="d-flex align-baseline mt-4 text-align_left">
          <v-checkbox
            style="position: absolute"
            v-model="agreement"
            @click="agreement != agreement"
          ></v-checkbox>
          <span class="mt-2">
            <span class="ml-7">{{ agreeDescription }}</span
            ><br />{{ agreeSubDescription }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          width="154"
          height="42"
          style=""
          class="ma-5 mt-0 btn btn_color_white"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!agreement"
          width="154"
          height="42"
          color="#EB5C6D"
          class="ma-5 mt-0 btn btn_color_red"
          style="color:white"
          @click="realDelete"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletePopup",
  data() {
    return {
      dialog: false,
      agreement: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    deleteFunction: {
      type: Function
    },
    isDescription: {
      type: Boolean,
      default: true
    },
    description: {
      type: String,
      default: ""
    },
    agreeDescription: {
      type: String,
      default: ""
    },
    agreeSubDescription: {
      type: String,
      default: ""
    }
  },
  methods: {
    showDialog() {
      if (
        this.$store.getters.getParentsList.count <= 1 &&
        this.$route.name != "ChildrenInfo" &&
        this.$route.name != "FromKindergartenChildren" &&
        this.$route.name != "FromKindergartenFromChildrenGroupChildrenInfo" &&
        this.$route.name != "FromChildrenGroupChildrenInfo"&&
        this.$route.name != "FromKindergartenFromChildrenGroupChildrenInfo" &&
        this.$route.name != "FromChildrenGroupChildrenInfo"&&
        this.$route.name != "FromKindergartenChildrenGroupsInfo"
      ) {
        this.$store.dispatch(
          "warningNotification",
          `Пользователь является единственным опекуном у воспитанника ${this.$store.getters.getChildrenInfos.name}. Добавьте к воспитаннику нового опекуна и повторите удаление`
        );
        this.close();
      } else this.dialog = true;
    },
    checkFunction() {},
    close() {
      this.agreement = false;
      this.dialog = false;
    },
    realDelete() {
      this.deleteFunction();
      this.close();
    }
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}

.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
