<template>
  <div>
    <div class="ma-6 d-flex justify-space-between">
      <template class="keep__title">
        <span class="keep__text">{{ keep }}</span>
      </template>
      <template>
        <router-link
          to="parentsCreate"
          append
          class="btn btn-add btn_color_green"
          >Добавить опекуна</router-link
        >
      </template>
    </div>

    <TableLayout
      :loading="isDateLoaded"
      to="parentInfos"
      class=""
      :source-object="sourceObject"
      :header="header"
      :table-data="sourceObject.results"
      v-if="sourceObject.results"
    />
  </div>
</template>

<script>
// import TableFilters from "@/components/tables/TableFilters";
import * as query from "@/helpers/query";
import TableLayout from "@/components/tables/TableLayout";
import { getKindergartenByIdClearRequest } from "@/helpers/api/kindergartens";
import moment from "moment";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "Parents",
  components: { TableLayout },
  data() {
    return {
      keep: "Опекуны:",
      loadingBtn: false,
      query: query.adminsOppen,
      objectId: null, // id объекта ДС
      header: [
        {
          text: "Телефон",
          renderFunction: value => phoneFilter.execute(value?.phone_number)
          // value: "phone_number"

          // renderFunction: value => `${value.phone_number}`
        },
        {
          text: "Пользователь",
          renderFunction: value => `${value.additional_fields[0].value}` || "-"
        },
        {
          text: "Комментарий",
          renderFunction: value => `${value.additional_fields[1].value}` || "-"
        },
        {
          text: "Регистрация",
          renderFunction: value =>
            `<p style='
        ${
          value.is_phone_confirmed === true
            ? "color: #95C23D!important;"
            : "color: #EB5C6D!important;"
        }'
      >${
        value.is_phone_confirmed === true && value.registered_at != null
          ? moment(value.registered_at * 1000).format("DD.MM.YYYY")
          : "Нет"
      }</p>`
        },
        // {
        //   text: "Телефон",
        //   renderFunction: value => phoneFilter.execute(value?.phone_number)
        //   // value: "phone_number"
        //
        //   // renderFunction: value => `${value.phone_number}`
        // },
        {
          text: "Доступные группы пользователей",
          renderFunction: value =>
            `${value?.access_groups
              ?.filter(el => {
                const orgId = this.$route.params.kindergartenId
                  ? this.$route.params.kindergartenId
                  : this.$store.getters.getCurrentOrganization;
                return el.organization == orgId;
              })
              .filter(elem => elem.title != "")
              .map(elem => elem.title + "<br/>")
              .join(" ")}`
        },

        // {
        //   text: "Карта",
        //   renderFunction: value => {
        //     const сardsObj = value.cards.filter(
        //       el => el.object == this.objectId
        //     ); //массив карт принадлеж. только данному ДС
        //     return сardsObj.length > 0
        //       ? сardsObj.reduce((accum, item) => {
        //           accum += `<div style="display:relative"><span> ${
        //             item.code
        //           }</span><span style="${
        //             item.status == "issued"
        //               ? "color: #95C23D!important;"
        //               : "color: #EB5C6D!important;"
        //           }"> ${item.status_display}</span></div>`;
        //           return accum
        //             ? `<div style="display:relative">${accum}</div>`
        //             : "";
        //         }, "")
        //       : value.card_is_awaited
        //       ? `<div style="display:flex">
        //           <span">Требуется ввод карты</span></div>`
        //       : `<div style="display:flex;">
        //           <span style="color: #EB5C6D!important;" v-if="currentRole != 31">Не оплачено</span></div>`;
        //   }
        // },
        {
          text: "Карта",
          renderFunction: value => {
            return value.card != null
              ? ` <span>${
                  value.card.code
                    ? value?.card.code
                    : value.card.is_awaited
                    ? "Требуется ввод карты "
                    : "Не оплачено"
                }</span>`
              : `<div style="display:relative"><span style="color: #EB5C6D!important;">Не оплачено</span></div>`;
          }
        },
        {
          text: "Приложение",
          renderFunction: value => {
            let access = value.actual_accesses; // выбранные группы
            if (access.length >= 2) {
              for (let i = 0; i < access.length; i++) {
                if (access[i].access_type == "app") {
                  access = [access[i]];
                }
              }
            }
            if (access && access.length > 0) {
              return access.reduce((res, access) => {
                if (access.access_by == "paid" && access.access_type == "app") {
                  res += `<div  style="color: #95C23D!important;">${moment(
                    access.date_end * 1000
                  ).format("DD.MM.YYYY")}</div>`;
                  return String(res);
                } else if (
                  access.access_by == "trial" &&
                  access.access_type == "app"
                ) {
                  res += `<div style="color: #95C23D!important;">Пробный, ${moment(
                    access.date_end * 1000
                  ).format("DD.MM.YYYY")}</div>`;
                  return res;
                } else if (
                  access.access_by == "unpaid" &&
                  access.access_type == "app"
                ) {
                  res += `<div style="color: #95C23D!important;">Бесплатный доступ</div>`;
                  return res;
                } else {
                  return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
                }
              }, "");
            } else {
              return `<div><span style="color: #EB5C6D!important">Не оплачено</span></div>`;
            }
          }
        }
      ]
    };
  },
  computed: {
    phone: {
      get() {
        return this.$store.getters.getCreateParentsForm.phone_number;
      },
      set(newValue) {
        this.$store.commit("setParentsCreateForm", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },
    currentOrganization() {
      return this.$store.getters.getCurrentOrganization;
    },
    currentKindergartnenId() {
      return [60, 70].includes(this.currentRole)
        ? this.$route.params.kindergartenId
        : this.currentOrganization;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getParentsList;
    }
  },
  methods: {
    addUser() {
      this.$store.commit("addParent", {});
      this.cardNumber = null;
    }
    // downloadList() {
    //   this.loadingBtn = true;
    //   this.$store
    //     .dispatch("downloadParentsList", {
    //       header: this.header,
    //       kindergartnerId: this.currentKindergartnenId
    //     })
    //     .then(() => {
    //       this.loadingBtn = false;
    //     });
    // }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        children: this.urlQuery.id
      }
    });
    this.isDateLoaded = true;
    const kinderObjectId = async () =>
      (
        await getKindergartenByIdClearRequest({
          id: this.currentKindergartnenId
        })
      ).data.object.id;
    kinderObjectId()
      .then(res => {
        this.objectId = res;
      })
      .then(() => {
        this.$store
          .dispatch("fetchParentsList", this.currentKindergartnenId)
          .finally(() => {
            this.isDateLoaded = false;
          });
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...this.urlQuery.query,
            children: this.urlQuery.id
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchParentsList").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
