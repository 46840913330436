<template>
  <v-dialog v-model="dialog" persistent max-width="520">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        style="color: aliceblue"
        class="btn btn-postpone btn_color_green"
      >
        Перенести в другую группу {{ name }}
      </button>
    </template>
    <v-card v-if="this.dialog">
      <v-card-title v-if="title" class="delete-title delete-center ">
        <div>Перенести в другую группу</div>
        <span class="delete-bold"> "{{ title }}" </span>
      </v-card-title>
      <div class="d-flex justify-space-around mt-4">
        <ValidationAutocompleteField
          :search-function="userGroupChildrenSearch"
          style="width: 45%"
          rules="required"
          label="Название текущей группы"
          v-model="currentСhildrenGroup"
          validate-name="номер группы"
          :disabled="true"
        />
        <ValidationAutocompleteField
          :search-function="childrenGroupSearch"
          style="width: 45%"
          rules="required"
          label="Название доступных групп"
          v-model="childrenGroup"
          validate-name="номер группы"
        />
      </div>

      <v-card-actions class="d-flex justify-end ">
        <v-btn
          width="154"
          height="42"
          color="#95c23d"
          class="ma-5 btn btn_color_white"
          text
          @click="close"
        >
          Отменить
        </v-btn>
        <v-btn
          width="154"
          height="42"
          class="btn btn_color_green"
          @click="realSave"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";

import { getChildrenGroupsRequest } from "@/helpers/api/childrenGroup";
import { getIdentificatorUserRole } from "../../helpers/Constants/roles";

export default {
  name: "ChangeParentPopup",
  components: {
    ValidationAutocompleteField
  },
  data() {
    return {
      userGroupChildrenSearch: async value => {
        return (
          await getChildrenGroupsRequest({
            query: {
              kindergarten_org: getIdentificatorUserRole(),
              search: value
            }
          })
        ).data.results.map(el => ({
          text: el.name,
          value: el.id,
          group: el.group
        }));
      },
      childrenGroupSearch: async value => {
        return (
          await getChildrenGroupsRequest({
            query: {
              kindergarten_org: getIdentificatorUserRole(),
              search: value
            }
          })
        ).data.results
          .filter(el => el.id !== this.currentСhildrenGroup)
          .map(el => ({
            text: el.name,
            value: el.id
          }));
      },
      dialog: false
    };
  },
  props: {
    name: {
      type: [String, Number]
    },
    title: {
      type: String
    },
    saveFunction: {
      type: Function
    }
  },

  computed: {
    currentСhildrenGroup: {
      get() {
        return this.$store.getters.getEditChildren.children_group.value;
      }
      // set(newValue) {
      //   this.$store.commit("changeEditChildren", {
      //     fieldName: "children_group",
      //     value: newValue
      //   });
      // }
    },

    childrenGroup: {
      get() {
        return this.$store.getters.getCreateChildren.children_group;
      },
      set(newValue) {
        this.$store.commit("setChildrenCreateForm", {
          listName: "children_group",
          value: newValue
        });
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    realSave() {
      if (this.childrenGroup) {
        this.saveFunction();
        this.close();
      }
    }
  },

  beforeDestroy() {
    this.$store.commit("clearCreateFormChildren", {
      fieldName: "createChildren"
    });
  },
  created() {
    this.$store.dispatch("prepareEditChildren", {
      id: this.$route.params.id
    });
  }
};
</script>

<style scoped>
.delete-title {
  padding-top: 30px !important;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px !important;
  /* or 143% */

  text-align: center !important;

  color: #515a6e;
}
.delete-bold {
  font-weight: 700 !important;
}

.delete-center {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}
</style>
